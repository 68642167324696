<template>
  <v-container>
    <v-row>
      <v-col cols="12" md="8" lg="6" xl="6" class="mx-auto">
        <v-card class="my-12">
          <v-card-text style="justify-content:center;">
            <v-row>
              <v-spacer></v-spacer>
              <v-icon x-large class="mt-4" color="success">mdi-send-check</v-icon>
              <v-spacer></v-spacer>
            </v-row>
            <v-card-title class="my-8" style="justify-content:center;">Je gegevens zijn succesvol verstuurd!</v-card-title>
            <v-row>
              <v-spacer></v-spacer>
              <v-btn color="secondary" class="my-4" @click="goBack">Ga terug</v-btn>
              <v-spacer></v-spacer>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "Success",

  methods: {
    goBack() {
      this.$router.push("/");
    },
  },
};
</script>
